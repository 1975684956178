<template>
  <div>
    <b-modal
      id="modal-import-grid"
      centered
      title="Importar grade "
      ok-only
      ok-title="Importar"
      ok-variant="success"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      @hide="onHide"
      :visible="isModalGridLessonImportActive"
      hide-footer
    >
      <b-card-text>
        <form @submit.prevent="submitLessonImports">
          <div class="d-block mb-2">
            <div class="form-row">
              <div class="col-12">
                <div class="form-group">
                  <label>
                    <i class="text-danger bi bi-record-circle"></i>
                    Qual grade deseja importar?
                  </label>
                  <v-select
                    label="title"
                    item-text="title"
                    item-value="code"
                    v-model="form.grid"
                    placeholder="Digite o título"
                    @search="fetchGrid"
                    :options="optionsGrids"
                    @input="gridSelected"
                  />
                </div>
              </div>

              <div v-if="optionsDisciplines.length" class="col-12">
                <div class="form-group">
                  <label>
                    <i class="text-danger bi bi-record-circle"></i>
                    Selecione 1 ou mais disciplinas
                  </label>
                  <v-select
                    multiple
                    label="title"
                    item-text="title"
                    item-value="code"
                    placeholder="Digite o título"
                    :options="optionsDisciplines"
                    @input="disciplinesSelected"
                  />
                </div>
              </div>

              <b-alert
                variant="primary"
                show
              >
                <h4 class="alert-heading">
                  Como funciona?
                </h4>
                <div class="alert-body">
                  <p>
                    Ao selecionar 1 ou mais disciplinas e clicar e importar, as aulas serão compartilhas entre as grades. Porém, se 1 aula for <strong>excluída de dentro da grade</strong>, ela também será excluída das demais grades.
                  </p>
                  <p class="mb-0">Ao clicar na aula, será possível visualizar quais grades estão compartilhando a aula.</p>
                </div>
              </b-alert>
            </div>
          </div>

          <hr />

          <div class="form-row justify-content-end">
            <div class="col-md-3">
              <button
                @click="closeModal"
                type="button"
                class="btn btn-light btn-block"
              >
                Fechar
              </button>
            </div>
            <div class="col-md-3">
              <button
                :disabled="submited"
                type="submit"
                class="btn btn-success btn-block"
              >
                <span v-if="submited">
                  <b-spinner small variant="light" />
                </span>
                <span> Importar </span>
              </button>
            </div>
          </div>
        </form>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BCard, BCardText, BModal, BSpinner, BAlert } from "bootstrap-vue";

import { defineComponent } from "@vue/composition-api";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";

export default defineComponent({
  name: "GridLessonImport",
  data() {
    return {
      submited: false,
      form: {
        grid: '',
        disciplines: [],
      },
      optionsGrids: [],
      optionsDisciplines: [],
    };
  },
  validations: {
    form: {
      grid: {
        required,
      },
      disciplines: {
        required,
      },
    },
  },
  props: {
    routeAction: {
      type: String,
      require: true,
    },
    isModalGridLessonImportActive: {
      type: Boolean,
      require: true,
      default: false,
    },
    gridUuid: {
      type: String,
    },
    classUuid: {
      type: String,
    },
  },
  components: {
    flatPickr,
    BCard,
    BCardText,
    BModal,
    BSpinner,
    vSelect,
    BAlert,
  },
  methods: {
    disciplinesSelected(event) {
      this.form.disciplines = event.map(disciplina => disciplina.discipline_workload_uuid);
    },
    gridSelected(event) {
      this.optionsDisciplines = [];
      this.form.disciplines = [];

      this.optionsDisciplines = event.disciplines
      delete this.form.grid.disciplines
    },
    async fetchGrid(term) {
      if (term.length > 1) {
        this.optionsGrids = await this.$store.dispatch("Grid/search", term);
      }
    },
    closeModal() {
      this.$store.dispatch("Grid/resetEvent");
      this.$bvModal.hide("modal-import-grid");
    },
    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
        this.$bvModal.hide("modal-import-grid");
      }
    },
    submitLessonImports() {
      this.$v.form.$touch();

      if (!this.$v.form.$error) {
        this.$emit('eventGridLessonImportSubmit', {
          grid: this.form.grid,
          disciplines: this.form.disciplines
        });
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
  },
});
</script>
