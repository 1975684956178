var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-import-grid",
            centered: "",
            title: "Importar grade ",
            "ok-only": "",
            "ok-title": "Importar",
            "ok-variant": "success",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "hide-header-close": "",
            visible: _vm.isModalGridLessonImportActive,
            "hide-footer": "",
          },
          on: { hide: _vm.onHide },
        },
        [
          _c("b-card-text", [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submitLessonImports.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "d-block mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [
                              _c("i", {
                                staticClass: "text-danger bi bi-record-circle",
                              }),
                              _vm._v(" Qual grade deseja importar? "),
                            ]),
                            _c("v-select", {
                              attrs: {
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                placeholder: "Digite o título",
                                options: _vm.optionsGrids,
                              },
                              on: {
                                search: _vm.fetchGrid,
                                input: _vm.gridSelected,
                              },
                              model: {
                                value: _vm.form.grid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "grid", $$v)
                                },
                                expression: "form.grid",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm.optionsDisciplines.length
                        ? _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Selecione 1 ou mais disciplinas "),
                                ]),
                                _c("v-select", {
                                  attrs: {
                                    multiple: "",
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    placeholder: "Digite o título",
                                    options: _vm.optionsDisciplines,
                                  },
                                  on: { input: _vm.disciplinesSelected },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "b-alert",
                        { attrs: { variant: "primary", show: "" } },
                        [
                          _c("h4", { staticClass: "alert-heading" }, [
                            _vm._v(" Como funciona? "),
                          ]),
                          _c("div", { staticClass: "alert-body" }, [
                            _c("p", [
                              _vm._v(
                                " Ao selecionar 1 ou mais disciplinas e clicar e importar, as aulas serão compartilhas entre as grades. Porém, se 1 aula for "
                              ),
                              _c("strong", [
                                _vm._v("excluída de dentro da grade"),
                              ]),
                              _vm._v(
                                ", ela também será excluída das demais grades. "
                              ),
                            ]),
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                "Ao clicar na aula, será possível visualizar quais grades estão compartilhando a aula."
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("hr"),
                _c("div", { staticClass: "form-row justify-content-end" }, [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-light btn-block",
                        attrs: { type: "button" },
                        on: { click: _vm.closeModal },
                      },
                      [_vm._v(" Fechar ")]
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success btn-block",
                        attrs: { disabled: _vm.submited, type: "submit" },
                      },
                      [
                        _vm.submited
                          ? _c(
                              "span",
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", variant: "light" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("span", [_vm._v(" Importar ")]),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }