// Full Calendar Plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import ptBRLocale from "@fullcalendar/core/locales/pt-br";

// Notification
import { useToast } from "vue-toastification/composition";
import Toastification from "@core/components/toastification/Toastification.vue";

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from "@vue/composition-api";
import store from "@/store";
import moment from "moment";

export default function userCalendar() {
 
  const toast = useToast();

  const events = computed(() => store.state.Grid.events)

  const calendarOptions = ref({
    locale: ptBRLocale,
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: "dayGridMonth",
    defaultView: "listMonth",
    headerToolbar: {
      start: "sidebarToggle, prev,next, title",
      end: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
    },
    timeZone: "America/Sao_Paulo",
    eventOrder: 'start',
    events: events,
    displayEventTime: false,
    editable: true,
    eventResizableFromStart: true,
    dragScroll: true,
    selectable: true,
    droppable: true,
    showNonCurrentDates : true,
    validRange: computed(() => {
      return {
        start: store.state.Grid.start,
        end: store.state.Grid.end,
      };
    }),
    loading: function (bool) {
      console.log('loading', bool);
    },
    addEventSource: () => {
      return [
        {
          start: start,
          end: end,
          rendering: "background",
          block: true,
        },
      ];
    },
    unselectAuto: false,
    selectOverlap: function (event) {
      console.log(event)
      return !event.block;
    },
    dayRender: function () {
      console.log("dayRender");
      if (cell.hasClass("fc-other-month")) {
        cell.addClass("disabled");
      }
    },
    eventRender: function(info) {
      console.log('eventRender chamado');
    },
    dayClick: function ({ jsEvent }) {
      console.log("dayClick");
      if ($(jsEvent.target).hasClass("disabled")) {
        return false;
      }
    },
    dayMaxEvents: 8,
    navLinks: false,
    customButtons: {
      sidebarToggle: {
        text: "sidebar",
        click() {
          console.log("customButtons");
        },
      },
    },
    dateClick: function(info) {
      console.log('dateClick')
      
      let days = store.state.Grid.days
      const day = moment(info.dateStr, 'YYYY-MM-DD').format('DD/MM/YYYY');
   
      if (!days.includes(day)) {
        days.push(day);
        info.dayEl.classList.add('day-selected');
      } else {
        let index = days.indexOf(day);

        if (index !== -1) {
          days.splice(index, 1);
        }

        info.dayEl.classList.remove('day-selected');
      }

      store.commit("Grid/UPDATE_DAYS", days);
    },

    eventClick(info) {
      isEventHandlerEditLesson.value = true;
      store.dispatch('Grid/showLesson', info.event.id)
    },

    clearDays() {
      const days = store.state.Grid.days;

      // Limpa os dias selecionados
      days.splice(0, days.length);

      // Atualiza o estado
      store.commit("Grid/UPDATE_DAYS", days);

      // Remove a classe 'day-selected' de todos os elementos
      const dayElements = document.querySelectorAll('.day-selected');
      dayElements.forEach(dayEl => {
        dayEl.classList.remove('day-selected');
      });
    },

    eventDrop({event, oldEvent}) {
      const date = event.startStr;
      const uuid = event.id;

      const dateNew = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
      const dateOld = moment(oldEvent.startStr, 'YYYY-MM-DD').format('DD/MM/YYYY');

      const data = {
        date : date
      }

      store.dispatch('Grid/updateEvent', { uuid, data }).then(() => {
        toast({
          component: Toastification,
          props: {
            title: "Data alterada com sucesso",
            icon: "bi-check",
            variant: "success",
            position: 'top-center',
            text: `Você acabou de alterar a data da aula. De <span class="text-danger">${dateOld}</span> para <span class="text-success">${dateNew}</span>.`
          },
        });
      })
    },
    eventResize() {
      console.log("eventResize");
    },
  });

  const isEventHandlerSidebarActive = ref(false);

  const isCalendarOverlaySidebarActive = ref(false);

  const isEventHandlerEditLesson = ref(false);

  store.dispatch('Grid/resetCalendar');
  
  return {
    isCalendarOverlaySidebarActive,
    calendarOptions,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
    isEventHandlerEditLesson,
  };
}
