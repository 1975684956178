var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-sidebar", {
        attrs: {
          id: "sidebar-add-new-event",
          "sidebar-class": "sidebar-lg",
          visible: _vm.isEventHandlerSidebarActive,
          "bg-variant": "white",
          shadow: "",
          backdrop: "",
          "no-header": "",
          right: "",
        },
        on: {
          change: function (val) {
            return _vm.$emit("update:is-event-handler-sidebar-active", val)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var hide = ref.hide
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1",
                  },
                  [
                    _c("h5", { staticClass: "mb-0" }, [
                      _vm._v(
                        _vm._s(_vm.eventLocal.id ? "Update" : "Add") + " Event"
                      ),
                    ]),
                    _c(
                      "div",
                      [
                        _vm.eventLocal.id
                          ? _c("feather-icon", {
                              staticClass: "cursor-pointer",
                              attrs: { icon: "TrashIcon" },
                              on: {
                                click: function ($event) {
                                  _vm.$emit("remove-event")
                                  hide()
                                },
                              },
                            })
                          : _vm._e(),
                        _c("feather-icon", {
                          staticClass: "ml-1 cursor-pointer",
                          attrs: { icon: "XIcon", size: "16" },
                          on: { click: hide },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "b-form",
                  {
                    staticClass: "p-2",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.handleSubmit(_vm.onSubmit)
                      },
                      reset: function ($event) {
                        $event.preventDefault()
                        return _vm.resetForm.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "Title", "label-for": "event-title" } },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "event-title",
                            autofocus: "",
                            trim: "",
                            placeholder: "Event Title",
                          },
                          model: {
                            value: _vm.eventLocal.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.eventLocal, "title", $$v)
                            },
                            expression: "eventLocal.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      { attrs: { label: "Calendar", "label-for": "calendar" } },
                      [
                        _c("v-select", {
                          attrs: {
                            dir: _vm.$store.state.appConfig.isRTL
                              ? "rtl"
                              : "ltr",
                            label: "label",
                            reduce: function (calendar) {
                              return calendar.label
                            },
                            "input-id": "calendar",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "option",
                                fn: function (ref) {
                                  var color = ref.color
                                  var label = ref.label
                                  return [
                                    _c("div", {
                                      staticClass:
                                        "rounded-circle d-inline-block mr-50",
                                      class: "bg-" + color,
                                      staticStyle: {
                                        height: "10px",
                                        width: "10px",
                                      },
                                    }),
                                    _c("span", [_vm._v(" " + _vm._s(label))]),
                                  ]
                                },
                              },
                              {
                                key: "selected-option",
                                fn: function (ref) {
                                  var color = ref.color
                                  var label = ref.label
                                  return [
                                    _c("div", {
                                      staticClass:
                                        "rounded-circle d-inline-block mr-50",
                                      class: "bg-" + color,
                                      staticStyle: {
                                        height: "10px",
                                        width: "10px",
                                      },
                                    }),
                                    _c("span", [_vm._v(" " + _vm._s(label))]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.eventLocal.extendedProps.calendar,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.eventLocal.extendedProps,
                                "calendar",
                                $$v
                              )
                            },
                            expression: "eventLocal.extendedProps.calendar",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Start Date",
                          "label-for": "start-date",
                        },
                      },
                      [
                        _c("flat-pickr", {
                          staticClass: "form-control",
                          attrs: {
                            config: {
                              enableTime: true,
                              dateFormat: "Y-m-d H:i",
                            },
                          },
                          model: {
                            value: _vm.eventLocal.start,
                            callback: function ($$v) {
                              _vm.$set(_vm.eventLocal, "start", $$v)
                            },
                            expression: "eventLocal.start",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      { attrs: { label: "End Date", "label-for": "end-date" } },
                      [
                        _c("flat-pickr", {
                          staticClass: "form-control",
                          attrs: {
                            config: {
                              enableTime: true,
                              dateFormat: "Y-m-d H:i",
                            },
                          },
                          model: {
                            value: _vm.eventLocal.end,
                            callback: function ($$v) {
                              _vm.$set(_vm.eventLocal, "end", $$v)
                            },
                            expression: "eventLocal.end",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: {
                              name: "check-button",
                              switch: "",
                              inline: "",
                            },
                            model: {
                              value: _vm.eventLocal.allDay,
                              callback: function ($$v) {
                                _vm.$set(_vm.eventLocal, "allDay", $$v)
                              },
                              expression: "eventLocal.allDay",
                            },
                          },
                          [_vm._v(" All Day ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: { label: "Event URL", "label-for": "event-url" },
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "event-url",
                            type: "url",
                            placeholder: "htttps://www.google.com",
                            trim: "",
                          },
                          model: {
                            value: _vm.eventLocal.eventUrl,
                            callback: function ($$v) {
                              _vm.$set(_vm.eventLocal, "eventUrl", $$v)
                            },
                            expression: "eventLocal.eventUrl",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Add Guests",
                          "label-for": "add-guests",
                        },
                      },
                      [
                        _c("v-select", {
                          attrs: {
                            dir: _vm.$store.state.appConfig.isRTL
                              ? "rtl"
                              : "ltr",
                            multiple: "",
                            "close-on-select": false,
                            options: _vm.guestsOptions,
                            label: "name",
                            "input-id": "add-guests",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "option",
                                fn: function (ref) {
                                  var avatar = ref.avatar
                                  var name = ref.name
                                  return [
                                    _c("b-avatar", {
                                      attrs: { size: "sm", src: avatar },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "ml-50 align-middle" },
                                      [_vm._v(" " + _vm._s(name))]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "selected-option",
                                fn: function (ref) {
                                  var avatar = ref.avatar
                                  var name = ref.name
                                  return [
                                    _c("b-avatar", {
                                      staticClass: "border border-white",
                                      attrs: { size: "sm", src: avatar },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "ml-50 align-middle" },
                                      [_vm._v(" " + _vm._s(name))]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.eventLocal.extendedProps.guests,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.eventLocal.extendedProps,
                                "guests",
                                $$v
                              )
                            },
                            expression: "eventLocal.extendedProps.guests",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Location",
                          "label-for": "event-location",
                        },
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "event-location",
                            trim: "",
                            placeholder: "Event Location",
                          },
                          model: {
                            value: _vm.eventLocal.extendedProps.location,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.eventLocal.extendedProps,
                                "location",
                                $$v
                              )
                            },
                            expression: "eventLocal.extendedProps.location",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Description",
                          "label-for": "event-description",
                        },
                      },
                      [
                        _c("b-form-textarea", {
                          attrs: { id: "event-description" },
                          model: {
                            value: _vm.eventLocal.extendedProps.description,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.eventLocal.extendedProps,
                                "description",
                                $$v
                              )
                            },
                            expression: "eventLocal.extendedProps.description",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex mt-2" },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(255, 255, 255, 0.15)",
                                expression: "'rgba(255, 255, 255, 0.15)'",
                                modifiers: { 400: true },
                              },
                            ],
                            staticClass: "mr-2",
                            attrs: { variant: "primary", type: "submit" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.eventLocal.id ? "Update" : "Add ") +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(186, 191, 199, 0.15)",
                                expression: "'rgba(186, 191, 199, 0.15)'",
                                modifiers: { 400: true },
                              },
                            ],
                            attrs: {
                              type: "reset",
                              variant: "outline-secondary",
                            },
                          },
                          [_vm._v(" Reset ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }