<template>
  <div
    class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1"
  >
    <div class="p-2">
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Filtrar aulas</span>
        </h5>
        <b-form-checkbox v-model="checkAll" class="mb-1">
          Visualizar todos
        </b-form-checkbox>
        <div class="form-group">

        </div>
        <div class="form-group">
          <b-form-checkbox-group
            v-model="disciplines"
            name="event-filter"
            stacked
          >
            <b-form-checkbox
              v-for="(element, index) in disciplines"
              :key="index"
              name="event-filter"
              :value="element.code"
              class="mb-1"
              :class="`custom-control-${element.color}`"
            >
              <strong> {{ element.workload }}h </strong> -
              {{ element.title }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </div>
      </div>
    </div>
    <b-img
      style="width: 90%; margin: auto; margin-bottom: 0px"
      :src="require('@/assets/images/pages/calendar-illustration.png')"
    />
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import useCalendarSidebar from "@/views/pedagogical-units/grid/useCalendarSidebar";
import vSelect from "vue-select";

import { mapState } from "vuex";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    vSelect,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
    optionsDisciplines: {
      type: Array,
      require: true,
    },
  },
  setup() {
    const { filters, checkAll } = useCalendarSidebar();

    return {
      filters,
      checkAll,
    };
  },
  computed: {
    ...mapState("Grid", ["filters", "disciplines", 'discipline']),
  },
};
</script>

<style></style>
