var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1",
    },
    [
      _c("div", { staticClass: "p-2" }, [
        _c(
          "div",
          { staticClass: "mt-3" },
          [
            _vm._m(0),
            _c(
              "b-form-checkbox",
              {
                staticClass: "mb-1",
                model: {
                  value: _vm.checkAll,
                  callback: function ($$v) {
                    _vm.checkAll = $$v
                  },
                  expression: "checkAll",
                },
              },
              [_vm._v(" Visualizar todos ")]
            ),
            _c("div", { staticClass: "form-group" }),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "b-form-checkbox-group",
                  {
                    attrs: { name: "event-filter", stacked: "" },
                    model: {
                      value: _vm.disciplines,
                      callback: function ($$v) {
                        _vm.disciplines = $$v
                      },
                      expression: "disciplines",
                    },
                  },
                  _vm._l(_vm.disciplines, function (element, index) {
                    return _c(
                      "b-form-checkbox",
                      {
                        key: index,
                        staticClass: "mb-1",
                        class: "custom-control-" + element.color,
                        attrs: { name: "event-filter", value: element.code },
                      },
                      [
                        _c("strong", [
                          _vm._v(" " + _vm._s(element.workload) + "h "),
                        ]),
                        _vm._v(" - " + _vm._s(element.title) + " "),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("b-img", {
        staticStyle: { width: "90%", margin: "auto", "margin-bottom": "0px" },
        attrs: {
          src: require("@/assets/images/pages/calendar-illustration.png"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "app-label section-label mb-1" }, [
      _c("span", { staticClass: "align-middle" }, [_vm._v("Filtrar aulas")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }